<template>
<div id="content" class="social-auth-error">
  <h2>{{ $t('auth.social_auth_error_title') }}</h2>
  <div
    class="social-auth-error-text"
    v-html="$t('auth.social_auth_error_text')"
  />
  <button
    class="social-auth-error-button"
    @click="gotoSignup"
  >
    {{ $t('signup') }}
  </button>
</div>
</template>

<script>

export default {
  name: 'social-auth-error',
  methods: {
    gotoSignup() {
      this.$router.push({ name: 'signup' });
    },
  },
};
</script>

<style lang="scss">
@import 'widgets';

#content {
  overflow: hidden;
  min-height: 400px;
  box-sizing: border-box;
}
.social-auth-error {
  margin: 0 auto;
  padding-top: 48px;
  text-align: center;

  .social-auth-error-text {
    margin: 12px 0 24px;
  }
  .social-auth-error-button {
    @include button();
  }
}
</style>
