<template>
<div id="content" class="authenticated">
  <h2 v-if="!missingInfo">
    {{ $t('auth.authenticating') }}
  </h2>
  <AuthLayout
    v-if="incompleteStatus"
    @close-modal="redirectUser"
  >
    <template #content>
      <CompleteInfo
        :key="missingInfoKey"
        :missingInfo="missingInfo"
        :authInfoComplete="authInfoComplete"
      />
    </template>
  </AuthLayout>
</div>
</template>

<script>
import { socialLogin } from '@/utils/api';
import CompleteInfo from '@/components/auth/CompleteInfo.vue';
import AuthLayout from '@/components/auth/AuthLayout.vue';
import { USER_REQUEST, SET_USER_SIGNUP } from '@/store/actions';

export default {
  name: 'authenticated',
  components: {
    CompleteInfo,
    AuthLayout,
  },
  data() {
    return {
      missingInfo: [],
      incompleteStatus: null,
    };
  },
  computed: {
    missingInfoKey() {
      const info = this.missingInfo;
      if(info && info.length > 0) {
        return info[0];
      }
      return 'none';
    },
  },
  methods: {
    async socialLogin() {
      try {
        // TODO: maybe there's a more elegant way to grab the site name
        const { path } = this.$route;
        const site = path.split('_')[0].slice(1);
        const { data: { new_user: newUser } } = await socialLogin(site);
        await this.$store.dispatch(SET_USER_SIGNUP, newUser);
        await this.$store.dispatch(USER_REQUEST, true);
        this.redirectUser();
      } catch(err) {
        if(err.errors) {
          this.missingInfo = err.errors;
          this.incompleteStatus = 'missing_info';
        } else {
          // TODO -- show error message for what's likely a server/oauth flow issue
          console.log('social login error: ', err);
        }
      }
    },
    async authInfoComplete() {
      await this.$store.dispatch(USER_REQUEST, true);
      if(this.$store.getters.userError) {
        this.incompleteStatus = 'missing_info';
        this.missingInfo = this.$store.getters.userError.errors || [];
      } else {
        this.redirectUser();
      }
    },
    redirectUser() {
      const { loginRedirect } = this.$store.getters;
      if(loginRedirect) {
        this.$router.push({ path: loginRedirect });
      } else {
        this.$router.push({ name: 'user' });
      }
    },
  },
  mounted() {
    // Automatically login and redirect to user profile
    this.socialLogin();
  },
};
</script>

<style lang="scss">

#content {
  overflow: hidden;
  min-height: 400px;
  box-sizing: border-box;
}
.authenticated {
  margin: 0 auto;
  padding-top: 48px;
  h2 {
    text-align: center;
    margin-top: 32px;
  }
}
</style>
